<template>
	<div class="d-flex justify-content-center">
		<div class="form" v-if="!submitted">
			<div class="col-12 mb-5">
				<div class="d-flex justify-content-between">
					<h1 class="mb-3" v-if="id">
						Edit an Issue - <span class="text-dark-50"> #{{ id }}</span>
					</h1>
					<h1 v-else>Log an Issue</h1>
					<div class="d-flex">
						<div>
							<router-link :to="{ name: 'dash' }">
								<b-button variant="outline-secondary" size="sm">Portal Home</b-button>
							</router-link>
						</div>
						<div>
							<router-link :to="{ name: 'issues' }">
								<b-button variant="outline-secondary" size="sm" class="ml-2">Issues</b-button>
							</router-link>
						</div>
						<div class="ml-2" v-if="admin">
							<div class="admin-setting" :class="{ 'show-admin': showAdmin }">
								<span class="svg-icon svg-icon-2x" @click="showAdminSettings()">
									<inline-svg src="/media/svg/icons/General/Settings-1.svg" />
								</span>
							</div>
						</div>
					</div>
				</div>
				<h3 class="mb-3 text-dark-50">Dev Team</h3>
				<div class="text-dark-50">
					Use this form to submit a request to the ITC Global Web Development team. Once submitted the web dev team will review your request and
					contact you at
				</div>
			</div>
			<div class="d-flex" v-if="showAdmin">
				<div class="col-6">
					<b-form-group label="Requestor:" label-for="requestor">
						<b-form-select v-model="data.username" class="mb-3">
							<b-form-select-option :value="user.username" v-for="user in users" :key="user.username">{{
								user.name + ' - ' + user.username
							}}</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</div>
				<div class="col-6">
					<b-form-group label="Created" label-for="created">
						<b-form-input type="date" id="created" v-model="data.created" />
					</b-form-group>
				</div>
				<!-- <div class="col-6">
					<b-form-group label="Requestor:" label-for="requestor">
						<b-form-select v-model="data.user_id" class="mb-3">
							<b-form-select-option :value="user.AccountID" v-for="user in users" :key="user.AccountID">{{
								user.name + ' - ' + user.AccountID
							}}</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</div> -->
			</div>
			<div class="d-flex">
				<div class="col-6">
					<b-form-group label="Project:" label-for="project" label-class="required-field">
						<b-form-select v-model="data.project" class="mb-3">
							<b-form-select-option value="VSAT Portal">VSAT Portal</b-form-select-option>
							<b-form-select-option value="NOC Portal">NOC Portal</b-form-select-option>
							<b-form-select-option value="Wallboard">
								Wallboard
							</b-form-select-option>
							<b-form-select-option value="CM4K">
								CM4K
							</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</div>
				<div class="col-6">
					<b-form-group label="Issue Type:" label-for="issue-type">
						<b-form-select v-model="data.type" class="mb-3">
							<b-form-select-option value="Bug">Bug</b-form-select-option>
							<b-form-select-option value="Feature">Feature Request</b-form-select-option>
							<b-form-select-option value="Report">Report Request</b-form-select-option>
							<b-form-select-option value="Info">Information Request</b-form-select-option>
							<b-form-select-option value="Other">Other</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</div>
			</div>
			<div class="d-flex" v-if="!currentUser.region || !currentUser.department">
				<div class="col-6">
					<b-form-group label="Your Region" label-for="region">
						<b-form-select v-model="data.region" @change="setUserData()">
							<b-form-select-option value="Americas">Americas</b-form-select-option>
							<b-form-select-option value="APAC">APAC</b-form-select-option>
							<b-form-select-option value="EMEA">EMEA</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</div>
				<div class="col-6">
					<b-form-group label="Your department" label-for="department">
						<b-form-input type="text" id="department" v-model="data.department" placeholder="Ex. NOC" @change="setUserData()" />
					</b-form-group>
				</div>
			</div>
			<div class="col-6">
				<b-form-group label="Request Severity:">
					<b-form-select v-model="data.severity">
						<b-form-select-option value="Low">Low &#8805; 5 days</b-form-select-option>
						<b-form-select-option value="Medium">Medium &#8804; 5 days</b-form-select-option>
						<b-form-select-option value="High">High &#8804; 2 days</b-form-select-option>
					</b-form-select>
				</b-form-group>
			</div>
			<div class="col-12">
				<b-form-group label="Issue Title" label-for="title">
					<b-form-input type="text" id="title" v-model="data.title" />
				</b-form-group>
			</div>
			<div class="col-12" v-if="data.type !== 'Bug'">
				<b-form-group label="Request Description">
					<b-form-textarea v-model="data.description" rows="7" />
				</b-form-group>
			</div>
			<div class="col-12">
				<b-form-group label="Link where bug is visible" label-for="link" v-if="data.type === 'Bug'">
					<b-form-input type="text" id="link" v-model="data.link" placeholder="ex. https://portal.itcglobal.com/site/7710" />
				</b-form-group>
				<b-form-group label="Link for context" label-for="link" v-else>
					<b-form-input type="text" id="link" v-model="data.link" />
				</b-form-group>
			</div>
			<div class="d-flex" v-if="data.type == 'Bug'">
				<div class="col-6">
					<b-form-group label="Expected" label-class="pb-0">
						<span class="form-text text-dark-50 mt-0"
							>Please describe what you expected to see or what the expected behavior of the component is</span
						>
						<b-form-textarea v-model="data.expected" rows="5"></b-form-textarea>
					</b-form-group>
				</div>
				<div class="col-6">
					<b-form-group label="Actual" label-class="pb-0">
						<span class="form-text text-dark-50 mt-0">Please describe the actual behavior that is different from what you expected</span>
						<b-form-textarea v-model="data.actual" rows="5"></b-form-textarea>
					</b-form-group>
				</div>
			</div>
			<div>
				<div class="col-12" v-if="data.type == 'Bug'">
					<b-form-group label="Additional Information">
						<b-form-textarea v-model="data.description" rows="7" />
					</b-form-group>
				</div>
			</div>
			<div class="col-12">
				<b-form-group label="Attach a file" label-class="pb-0">
					<span class="form-text text-muted mt-0">Add a screenshot or relevant document for reference. Hold shift to select more than one</span>
					<b-form-file v-model="data.file" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." multiple></b-form-file>
				</b-form-group>
				<!-- <div v-if="data.file" class="mb-5">
					<div class="mt-3" v-for="file in data.file" :key="file.name">Selected file: {{ file.name }}</div>
				</div> -->
			</div>
			<div class="col-12 text-center">
				<b-button :to="{ name: 'issues' }" variant="outline-secondary" class="mr-3 px-7">Cancel</b-button>
				<b-button variant="success" @click="submit()" class="px-7">Submit</b-button>
			</div>
		</div>

		<!-- SUBMITTED DIALOGUE BOX -->
		<div v-if="submitted" class="mt-10">
			<div class="submitted-card d-flex align-items-center justify-content-center flex-column p-10">
				<h1 class="mb-3">
					<i class="fa fa-check-circle text-success" style="font-size: 1.5em"></i>
				</h1>
				<h3 class="mb-5 font-weight-light text-success">
					Issue Submitted
				</h3>
				<h6 class="text-center font-weight-light mb-4">
					<p>
						The Web Team will review your request and contact you on Teams or using the email on file:
						<span class="text-dark-50">{{ currentUser.email }}</span>
					</p>
					<p>
						If needed you can contact the team via
						<a href="mailto:webdev@itcglobal.com">webdev@itcglobal.com</a>
					</p>
				</h6>
				<router-link :to="{ name: 'home' }"> <button class="btn btn-primary" style="width:200px">Return to VSAT Portal</button></router-link>

				<a href="#" @click="reset()" class="mt-4 ">Submit Another Issue</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_USER_PROFILE } from '@/core/services/store/profile.module';

export default {
	name: 'IssueReport',
	props: {
		id: {
			type: Number,
			required: false,
		},
	},
	data() {
		return {
			data: {
				user_id: null,
				department: '',
				region: '',
				account_data_set: false,
				project: 'VSAT Portal',
				type: 'Bug',
				severity: 'Low',
				title: '',
				description: '',
				link: '',
				expected: '',
				actual: '',
			},
			loaded: false,
			showAdmin: false,
			submitted: false,
			users: [],
		};
	},
	computed: {
		...mapGetters(['currentUser']),
		admin() {
			return ['jpalacios', 'limep', 'jimenezj'].includes(this.currentUser.username);
		},
	},
	methods: {
		submit() {
			let formData = new FormData();
			for (let key in this.data) {
				if (key == 'file') {
					this.data[key].forEach(file => {
						formData.append(key + '[]', file, file.name);
					});
				} else {
					formData.append(key, this.data[key]);
				}
			}
			this.$http.post('/issues', formData).then(resp => {
				if (resp.data.status == 200) {
					this.submitted = true;
					if (this.data.account_data_set === true) {
						this.$store.dispatch(GET_USER_PROFILE);
					}
				}
			});
		},
		setUserData() {
			if (this.data.department !== '' && this.data.region !== '') {
				this.data.account_data_set = true;
			}
		},
		reset() {
			this.data = {
				username: this.currentUser.username,
				department: '',
				region: '',
				account_data_set: false,
				project: 'VSAT Portal',
				type: 'Bug',
				severity: 'Low',
				title: '',
				description: '',
				link: '',
				expected: '',
				actual: '',
			};
			this.submitted = false;
		},
		showAdminSettings() {
			if (!this.showAdmin) {
				if (this.users.length == 0) {
					this.$http.get('/issues/adminoptions').then(({ data }) => {
						this.users = data.users;
					});
				}
			} else {
				this.data.username = this.currentUser.username;
			}
			this.showAdmin = !this.showAdmin;
		},
	},
	created() {
		this.data.username = this.currentUser.username;
		if (this.$route.name == 'editissue') {
			this.$http.get(`/issue/${this.id}`).then(({ data }) => {
				this.data = data;
			});
		}
	},
};
</script>

<style scoped lang="scss">
.form {
	max-width: 850px;
	background: white;
	padding: 25px;
	margin: 25px 0;
	border-radius: 7px;
	position: relative;
}
.admin-setting {
	// position: absolute;
	// right: 10px;
	// top: 0px;
	padding: 3px;
	border: 1px solid $primary-light;
	border-radius: 5px;
	transition: all 300ms ease-in-out;

	::v-deep .svg-icon svg path {
		fill: $primary;
	}

	&:hover {
		border: 1px solid $primary;
		background: $primary;

		::v-deep .svg-icon svg path {
			fill: white;
		}
	}
}
.svg-icon {
	cursor: pointer;
}
.show-admin {
	border: 1px solid $primary;
	background: $primary;

	::v-deep .svg-icon svg path {
		fill: white;
	}
}
.submitted-card {
	background: white;
	width: 600px;
	height: 400px;
	border-radius: 7px;
}
</style>
